import { Component, OnInit,  } from '@angular/core';



@Component({
  selector: 'imp-contact',
  templateUrl: './imp-contact.component.html',
  styleUrls: ['./imp-contact.component.scss'],
})
export class ImpContactComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
