import { Component } from '@angular/core';
import { ImpTableControllerComponent } from '../../imp-table/imp-table-controller/imp-table-controller.component';
import { ImpTicketControllerComponent } from '../../imp-ticket/imp-ticket-controller/imp-ticket-controller.component';

@Component({
  selector: 'app-user-orders',
  templateUrl: './user-orders.component.html',
  styleUrls: ['./user-orders.component.scss']
})
export class UserOrdersComponent {
  selectedTab: string = 'tickets';

  // Optionally, if you need to declare components explicitly
  // Use only if you're extending or modifying a NgModule
  // declarations: any[] = [ImpTableControllerComponent, ImpTicketControllerComponent];
}
