import { Component, OnInit,  } from '@angular/core';



@Component({
  selector: 'app-imp-terms-static',
  templateUrl: './imp-terms-static.component.html',
  styleUrls: ['./imp-terms-static.component.scss'],
})
export class ImpTermsStaticComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
