import { Component, OnInit, Input, ViewChild, ElementRef,  } from '@angular/core';
import { IonSlides } from '@ionic/angular';
import { environment } from '../../../environments/environment';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

const SLIDE_OPTIONS = {
  slidesPerView: 2,
  spaceBetween: 10,
  pager:        true,
};
const DEFAULT_TIMEOUT = environment.media.timeout;



@Component({
  selector: 'app-imp-medias',
  templateUrl: './imp-medias.component.html',
  styleUrls: ['./imp-medias.component.scss'],
})
export class ImpMediasComponent implements OnInit {

  @Input() slides: any[] = [];
  // @Input() lockSwipes: boolean = false;
  // @Input() showProgress: boolean = false;

  @ViewChild('ionSlides', { static: false }) ionSlides: IonSlides;

  private interval: any;

  constructor(private iab: InAppBrowser) { }

  ngOnInit() {
    this.interval = setInterval(() => {
      this.mediaEndedHandler();
    }, DEFAULT_TIMEOUT);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  public get slideOptions(): any  {
    return SLIDE_OPTIONS;
  }

  public async mediaEndedHandler() {
    try {
      if (await this.ionSlides.isEnd()) {
        this.ionSlides.slideTo(0);
        console.log('[imp-medias.mediaEndedHandler] Slide to 0');
      } else {
        this.ionSlides.slideNext();
        console.log('[imp-medias.mediaEndedHandler] Slide to next');
      }
    } catch (error) {
      console.error('[imp-medias.mediaEndedHandler] ERROR: ' + error);
    }
  }

  public async slideChanged() {
    console.log('Slide Changed!');
    console.log(await this.ionSlides.getActiveIndex());
    console.log();
    // this.ionSlides.pager = true;
  }

  openUrl(url: string) {
    this.iab.create(url, "_system");
  }
}
