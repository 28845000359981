const SPACE: string = 'ku5vvwzkc1mx';
const ENV: string = 'master';

export default {
    production: {
        host: undefined,
        space: SPACE,
        environment: ENV,
        accessToken: 'qPBouvg2LrQbwM6AOnr_gZtPMlYwBhb5Ewqz2rc6pns',    
    },
    preview: {
        host: 'preview.contentful.com',
        space: SPACE,
        environment: ENV,
        accessToken: 'CLKt4ddbhXMRU6sutbm4-uwBYy05gJJdcU-w8FYf2MU',    
    },
}

