import versionConfig from './version';
import firebaseConfig from './firebase';
import stripeConfig from './stripe';
import scannerConfig from './scanner';
import driversLicenseConfig from './drivers-license';
import durationsConfig from './durations';
import contentfulConfig from './contentful';
import mediaConfig from './media';

//Default enviroment will be set to DEV enviroment 
export const environment = {
  production:     false,
  name:           "(DEV)",
  version:        versionConfig.dev,
  stripe:         stripeConfig.dev,
  firebase:       firebaseConfig.dev,
  scanner:        scannerConfig,
  driversLicense: driversLicenseConfig,
  durations:       durationsConfig,
  contentful:     contentfulConfig.preview,
  media:          mediaConfig,
};
