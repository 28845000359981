import { Component, OnInit,  } from '@angular/core';



@Component({
  selector: 'imp-service',
  templateUrl: './imp-service.component.html',
  styleUrls: ['./imp-service.component.scss'],
})
export class ImpServiceComponent implements OnInit {
  date: Date;
  company: string = 'promotional company';
  constructor() {
    this.date = new Date();
  }

  ngOnInit() {}

}
