import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { HttpClientModule } from '@angular/common/http';

import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/functions';

import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { FormsModule } from '@angular/forms';
import { ClipboardModule } from 'ngx-clipboard';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ModalModule } from './home/imp-modal/imp-modal.module';
import { ImpMediasComponent } from './home/imp-medias/imp-medias.component';
import { UserOrdersComponent } from './home/tdct-user/user-orders/user-orders.component';
import { SitemapComponent } from './sitemap/sitemap.component';
import { ImageModalComponent } from './image-modal/image-modal.component'; // Adjust the path accordingly

@NgModule({
  declarations: [
    AppComponent,
    UserOrdersComponent,
    SitemapComponent,
    ImageModalComponent

  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    ImageCropperModule,
    NgxQRCodeModule,
    FormsModule,
    HttpClientModule,
    ClipboardModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    BarcodeScanner,
    InAppBrowser,
    ModalModule,
    ImpMediasComponent,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: REGION, useValue: 'us-central1' },
    Deeplinks
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
