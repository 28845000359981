/* 
=========================================================================================================
app.component.ts
----------------
Declares the appComponent class and houses it's respective functions.

=========================================================================================================
*/
import { Component, NgZone } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ImpThemeService } from './home/imp-theme/imp-theme.service';
import { DarkTheme } from './home/imp-theme/imp-theme.mocks';
import { TdctAuthService } from './home/tdct-auth/tdct-auth.service';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { ModalService } from './home/imp-modal/imp-modal.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
   public isLoggedIn: boolean = true;
    envName = environment.name;
   public menuItems: any[] = [];
   userRole: string = '';
    public APP_VERSION: string = environment.version.appVersion;

  constructor(
    public auth: TdctAuthService,
    public theme: ImpThemeService,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private zone: NgZone,
    private deeplinks: Deeplinks,
    private modalService: ModalService,
    private iab: InAppBrowser,
    public afa: AngularFireAuth,
  ) {
    this.initializeApp();
    this.setupMenuItems();
    this.fetchUser();
  }

  toggleTheme () {
      this.theme.toggle();
  }

  async fetchUser() {
    await this.afa.authState.subscribe(async (state) => {
      if (state) { 
        await  this.setupMenuItems(); 
      } 
    });
  }
   
  async setupMenuItems() {
    try {
      this.userRole = await this.auth.getUserRole();
  
      switch (this.userRole) {
        case 'admin':
          console.log('Setting up admin menu items');
          this.menuItems = [
            { title: 'Manage Users', icon: 'people-outline', route: '/admin/manage/users' },
            { title: 'Manage Events', icon: 'calendar-outline', route: '/admin/manage/events' },
            { title: 'Create Event', icon: 'add-circle-outline', route: '/promoter/create/event' },
            { title: 'Dashboard', icon: 'stats-chart-outline', route: '/admin/dashboard' },
            { title: 'Settings', icon: 'settings-outline', route: '/account' },
            { title: 'Promoter Panel', icon: 'business-outline', route: '/admin/promoter/panel' },
            { title: 'Promoter Requests', icon: 'help-circle-outline', route: '/admin/manage/promoter/requests' },
         //   { title: 'Analytics', icon: 'analytics-outline', route: '/admin/analytics' },
             { title: 'Analytics', icon: 'analytics-outline', route: '/analytics' },
          ];
          break;
  
        case 'promoter':
          console.log('Setting up promoter menu items');
          this.menuItems = [
            { title: 'My Events', icon: 'calendar-outline', route: '/user/profile/promoter/events' },
            { title: 'Create Event', icon: 'add-circle-outline', route: '/promoter/create/event' },
            { title: 'Analytics', icon: 'analytics-outline', route: '/analytics' },
        //    { title: 'Analytics', icon: 'analytics-outline', route: '/promoter/analytics' },
        //    { title: 'Profile', icon: 'person-outline', route: '/promoter/profile' },
            //{ title: 'Profile', icon: 'person-outline', route: '/account' },
            { title: 'Settings', icon: 'settings-outline', route: '/account' },
            { title: 'Sitemap', icon: 'map-outline', route: '/sitemap' },

          ];
          break;
  
        default:
          console.log('Setting up default menu items');
          this.menuItems = [
            { title: 'Featured', icon: 'star-outline', route: '/home' },
            { title: 'Cities', icon: 'map-outline', route: '/events' },
            { title: 'Orders', icon: 'receipt-outline', route: '/orders' },
            { title: 'Settings', icon: 'settings-outline', route: '/account' },
            { title: 'Sitemap', icon: 'map-outline', route: '/sitemap' },
          ];
          break;
      }
    } catch (error) {
      console.error('Error setting up menu items:', error);
      this.userRole = 'user'; 
      this.menuItems = [
        { title: 'Featured', icon: 'star-outline', route: '/home' },
        { title: 'Cities', icon: 'map-outline', route: '/events' },
        { title: 'Orders', icon: 'receipt-outline', route: '/orders' },
        // { title: 'Tickets', icon: 'ticket-outline', route: '/orders' },
        // { title: 'Events', icon: 'calendar-outline', route: '/user/events' },
        { title: 'Settings', icon: 'settings-outline', route: '/account' },
        { title: 'Sitemap', icon: 'map-outline', route: '/sitemap' },
        // Add more user-specific menu items here      ;
      ]
      }
  }

  initializeApp() {
    // deeplink listener event 
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
          // Example url: https://beerswift.app/tabs/tab2
          // slug = /tabs/tab2
          // let env_name = environment.name;
          // let domain = "";
          // if(env_name === '(DEV)'){
          //     domain = "imp-app-dev.web.app"
          // } else if (env_name === '(UAT)'){
          //   domain = "imp-app-uat.web.app"
          // } else if (env_name === '(PROD)'){
          //     domain = `imp-events.com`;
          // }
          const domain = "imp-events.com";
          const slug = event.url.split(domain).pop();
          if (slug) {
              this.router.navigateByUrl(slug);
          }
          // If no match, do nothing - let regular routing
          // logic take over
      });
    });
    
    this.platform.ready().then(() => {
      this.theme.set(DarkTheme);
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      
    });
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  openUrl(url: string) {
    this.iab.create(url, '_system');
  }
  
}