import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss'],
})
export class ImageModalComponent {
  @Input() imageUrl: string;

  constructor(private modalCtrl: ModalController) {}

    ngOnInit() {
    console.log('Image URL in modal:', this.imageUrl); // Log the image URL
  }

  close() {
    this.modalCtrl.dismiss();
  }
}
