import { Component, ViewEncapsulation, ElementRef, Input, OnInit, OnDestroy,  } from '@angular/core';

import { ModalService } from './imp-modal.service';
import {TdctAuthSignupFormComponent} from '../tdct-auth/tdct-auth-signup-form/tdct-auth-signup-form.component';
import {TdctUserService} from '../tdct-user/tdct-user.service';
import { ImpEmitterService } from '../imp-emitter.service';


@Component({
    selector: 'imp-modal',
    templateUrl: 'imp-modal.component.html',
    styleUrls: ['imp-modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ModalComponent implements OnInit {
    @Input() modalId: string;
    private readonly element: any;

    constructor(private modalService: ModalService,
                private el: ElementRef,
                private emitterService: ImpEmitterService) {
        this.element = el.nativeElement;
    }

    ngOnInit(): void {
        // ensure id attribute exists
        if (!this.modalId) {
            console.error('modal must have an id');
            return;
        }

        // move element to bottom of page (just before </body>) so it can be displayed above everything else
        // document.body.appendChild(this.element);

        // close modal on background click
        this.element.addEventListener('click', el => {
            if (el.target.className === 'imp-modal') {
                this.close();
            }
        });

        // add self (this modal instance) to the modal service so it's accessible from controllers
        this.modalService.add(this);
    }

    /*// remove self from modal service when component is destroyed
    ngOnDestroy(): void {
        this.modalService.remove(this.modalId);
        this.element.remove();
    }*/

    // open modal
    open(): void {
        this.element.style.display = 'block';
        document.body.classList.add('imp-modal-open');
    }

    // close modal
    close(): void {
        this.element.style.display = 'none';
        document.body.classList.remove('imp-modal-open');
    }

    acceptTerms(decision: boolean) {
        this.close();
        if (decision === true) {
            this.emitterService.acceptTerms(decision);
        }
    }
}

