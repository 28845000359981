import { Component, OnInit,  } from '@angular/core';



@Component({
  selector: 'app-imp-privacy-static',
  templateUrl: './imp-privacy-static.component.html',
  styleUrls: ['./imp-privacy-static.component.scss'],
})
export class ImpPrivacyStaticComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
