import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ModalService {
  private modals: any[] = [];

  add(modal: any) {
    // add modal to array of active modals
    this.modals = this.modals.filter(x => x.modalId !== modal.modalId);
    this.modals.push(modal);
  }

  remove(id: string) {
    // remove modal from array of active modals
    this.modals = this.modals.filter(x => x.modalId !== id);
  }

  open(id: string) {
    // open modal specified by id
    /* this.modals.filter(x => x.modalId === id).forEach(x => {
      x.open();
    }); */
    console.log('[imp-modal.service]open id:', id);
    const modal = this.modals.find(x => x.modalId === id);
    console.log('[imp-modal.service]open modal:', modal);
    modal.open(id);
  }

  close(id: string) {
    // close modal specified by id
    const modal = this.modals.find(x => x.modalId === id);
    modal.close();
  }
}
