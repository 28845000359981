export default {
    formats:                "PDF_417",
    preferFrontCamera:      false,
    showFlipCameraButton:   true,
    showTorchButton:        true,
    disableAnimations:      true,
    disableSuccessBeep:     false,
    torchOn:                true,
    saveHistory:            false,
    orientation:            "landscape", // comment this line for auto rotate mode
    resultDisplayDuration:  1500,
};